import React from 'react';

const Scroll = (props: any) => 
{
    return (
        <div className="scrollComponent">
            {props.children}
        </div>
    );
}

export default Scroll;