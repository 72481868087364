import {
    REQUEST_SOUNDS_PENDING,
    REQUEST_SOUNDS_SUCCESS,
    REQUEST_SOUNDS_FAILED, PREVIEW_CLIP_SUCCESS, PREVIEW_CLIP_FAILED, CREATE_CLIP_SUCCESS, CREATE_CLIP_FAILED
} from '../constants';

const initialStateSounds =
{
    isPending: false,
    sounds: [],
    error: ""
}

export const requestSounds = (state=initialStateSounds, action: any = {}) => 
{
    switch(action.type)
    {
        case REQUEST_SOUNDS_PENDING: 
        {
            return Object.assign({}, state, { isPending: true });
        }
        case REQUEST_SOUNDS_SUCCESS:
        {
            return Object.assign({}, state, { sounds: action.payload, isPending: false });
        }
        case REQUEST_SOUNDS_FAILED:
        {
            return Object.assign({}, state, { error: action.payload, isPending: false });
        }
        default:
            return state;
    }
}

const initialStateClip =
{
    status: null,
    errors: null,
}

export const previewClip = (state=initialStateClip, action: any = {}) =>
{

    switch(action.type)
    {
        case PREVIEW_CLIP_SUCCESS:
        {
            return Object.assign({}, state, { status: "PREVIEW SUCCESS", errors: null });
        }
        case PREVIEW_CLIP_FAILED:
        {
            return Object.assign({}, state, { status: "ERROR", errors: action.errors});
        }
        case CREATE_CLIP_SUCCESS:
        {
            return Object.assign({}, state, { status: "SUCCESS", errors: null });
        }
        case CREATE_CLIP_FAILED:
        {
            return Object.assign({}, state, { status: "ERROR", errors: action.errors});
        }
        default:
            return state;
    }
}

