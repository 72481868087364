import axios from 'axios';
import { API_SERVER_ADDRESS } from '../../settings';
import {
    CHANGE_SEARCH_FIELD,
    REQUEST_SOUNDS_PENDING,
    REQUEST_SOUNDS_SUCCESS,
    REQUEST_SOUNDS_FAILED,
    EDIT_SOUND_START,
    REQUEST_CATEGORIES_PENDING,
    REQUEST_CATEGORIES_SUCCESS,
    REQUEST_CATEGORIES_FAILED,
    PLAY_IN_BROWSER_SUCCESS,
    PLAY_IN_BROWSER_FAILED,
    PREVIEW_CLIP_SUCCESS,
    PREVIEW_CLIP_FAILED,
    CREATE_CLIP_SUCCESS,
    CREATE_CLIP_FAILED
} from '../constants';

export type PlaySoundProps =
{
    id: number,
    override: boolean,
    authentication: string | null
}

export type GetAudioProps =
{
    id: number,
    authentication: string | null
}

export type EditSoundProps =
{
    id: number,
    authentication: string | null
}

export type PreviewClipProps =
{
    name: string,
    ytUrl: string,
    startMs: number,
    endMs: number,
    authentication: string | null
}

export type CreateClipProps =
{
    name: string,
    ytUrl: string,
    categories: number[],
    startMs: number,
    endMs: number,
    authentication: string | null
}

export const requestCategories = (authentication: string | null) => (dispatch: any) => {
    const token = authentication ? authentication : null;
    if (!token)
        return;
    dispatch({type: REQUEST_CATEGORIES_PENDING, payload: []});
    axios.get(API_SERVER_ADDRESS+'/categories', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then((response) => {
        dispatch({type: REQUEST_CATEGORIES_SUCCESS, payload: response.data});
    })
    .catch(err => {
        dispatch({type: REQUEST_CATEGORIES_FAILED, payload: err})
    });
}

//Redux thunk allows actions to also return a function as opposed to only returning an object.
//Redux thunk will provide the dispatch-parameter function which can be used to dispatch actions
//as they happen.
export const requestSounds = (authentication: string | null) => (dispatch: any) => {
    const token = authentication ? authentication : null;
    if (!token)
        return;

    dispatch({type: REQUEST_SOUNDS_PENDING, payload: []});
    axios.get(API_SERVER_ADDRESS+'/sound_effects', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then((response) => {
        dispatch({type: REQUEST_SOUNDS_SUCCESS, payload: response.data});
    })
    .catch(err => {
        dispatch({type: REQUEST_SOUNDS_FAILED, payload: err})
    });
}

export const playSound = (props: PlaySoundProps) => (dispatch: any) => {
    const body = JSON.stringify({
            sound_effect_id: props.id,
            override: props.override
        })
    axios.post(API_SERVER_ADDRESS+`/bot/play_sound`, body, {
        headers: {
            Authorization: `Bearer ${props.authentication}`,
            "Content-Type": "application/json"
        }
    })
    .then(response => console.log(response.data))
    .catch(err => console.log(err));
}

export const getAudio = (props: GetAudioProps) => (dispatch: any) =>
{
    axios.get(API_SERVER_ADDRESS+'/sound_effects/'+props.id+'/audio', {
        headers: {
            Authorization: `Bearer ${props.authentication}`
        },
        responseType: 'blob',
    })
    .then((response) => {
        const audioBlob = new Blob([response.data], {type: 'audio/ogg'}) // Convert response to blob
        const objectUrl = URL.createObjectURL(audioBlob); // And make an object Url so we can set it as src
        const audio = document.getElementById('soundPlayer') as HTMLAudioElement;
        audio.src = objectUrl;
        audio.onload = function () {
            URL.revokeObjectURL(objectUrl); // After loading there is no reason to keep the file reference around
        };
        audio.play();
        dispatch({type: PLAY_IN_BROWSER_SUCCESS, payload: response.data});
    })
    .catch(err => {
        dispatch({type: PLAY_IN_BROWSER_FAILED, payload: err})
    });
}

export const previewClip = (props: PreviewClipProps) => (dispatch: any) =>
{
    axios.get(API_SERVER_ADDRESS+'/sound_effects/from_yt', {
        headers: {
            Authorization: `Bearer ${props.authentication}`
        },
        params: {
            name: props.name,
            yt_url: props.ytUrl,
            start_ms: props.startMs,
            end_ms: props.endMs
        },
        responseType: 'blob',

    })
    .then((response) => {
        const audioBlob = new Blob([response.data], {type: 'audio/ogg'}) // Convert response to blob
        const objectUrl = URL.createObjectURL(audioBlob); // And make an object Url so we can set it as src
        const audio = document.getElementById('previewPlayer') as HTMLAudioElement;
        audio.src = objectUrl;
        audio.onload = function () {
            URL.revokeObjectURL(objectUrl); // After loading there is no reason to keep the file reference around
        };
        audio.play();
        dispatch({type: PREVIEW_CLIP_SUCCESS, errors: null});
    })
    .catch(err => {
        const reader = new FileReader()
        reader.addEventListener("loadend", (e) => {
            if(e !== null && e.target !== null){
                const text = e.target.result;
                dispatch({type: PREVIEW_CLIP_FAILED, errors: text})
            }
        })
        reader.readAsText(err.response.data, "UTF-8")
    });
}

export const createClip = (props: CreateClipProps) => (dispatch: any) =>
{
    const body = {
        name: props.name,
        yt_url: props.ytUrl,
        start_ms: props.startMs,
        categories: props.categories,
        end_ms: props.endMs
    }
    axios.post(API_SERVER_ADDRESS+'/sound_effects/from_yt', body, {
        headers: {
            Authorization: `Bearer ${props.authentication}`
        },
    })
    .then((response) => {
        dispatch({type: CREATE_CLIP_SUCCESS, errors: null});
    })
    .catch(err => {
        dispatch({type: CREATE_CLIP_FAILED, errors: JSON.stringify(err.response.data)})
    });
}

export const editSound = (props: EditSoundProps) => (dispatch: any) =>
{
    // TODO: Fetch the given sound information from database and if successful, open 
    // the editor screen.
    dispatch({type: EDIT_SOUND_START, payload: props.id})
}