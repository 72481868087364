import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';

//TODO: REMOVE LOGGER IN RELEASE
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import { BrowserRouter, Route } from 'react-router-dom';
import { getAccessToken } from './store/helpers';
import reducers from './store/reducers'
import App from './containers/App';
import Welcome from './containers/pages/welcome/Welcome';
import SignIn from './containers/pages/signin/SignIn';
import SignOut from './containers/pages/signout/SignOut';
import SoundListPage from './containers/pages/soundlistpage/SoundListPage';
import "./index.scss";
import AddClipPage from "./containers/pages/addclippage/AddClipPage";

const accessToken = getAccessToken();

accessToken.then(token => {
    const initialState = {
        signIn: {
            authentication: token,
            errorMessage: "",
            isPending: false
        }
    }

    const logger = createLogger();
    const store = createStore(reducers, initialState, applyMiddleware(thunkMiddleware, logger));

    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <App>
                    <Route path="/" exact component={Welcome} />
                    <Route path="/dashboard" exact component={SoundListPage} />
                    <Route path="/addclip" exact component={AddClipPage} />
                    <Route path="/signin" exact component={SignIn} />
                    <Route path="/signout" exact component={SignOut} />
                </App>
            </BrowserRouter>
        </Provider>,
        document.getElementById('root')
    );
})

