import {
    REQUEST_CATEGORIES_SUCCESS,
    REQUEST_CATEGORIES_PENDING,
    REQUEST_CATEGORIES_FAILED
} from '../constants';


const initialStateCategories =
{
    isPending: false,
    categories: [],
    error: ""
}

export const requestCategories = (state=initialStateCategories, action: any = {}) =>
{
    switch(action.type)
    {
        case REQUEST_CATEGORIES_PENDING:
        {
            return Object.assign({}, state, { isPending: true });
        }
        case REQUEST_CATEGORIES_SUCCESS:
        {
            return Object.assign({}, state, { categories: action.payload, isPending: false });
        }
        case REQUEST_CATEGORIES_FAILED:
        {
            return Object.assign({}, state, { error: action.payload, isPending: false });
        }
        default:
            return state;
    }
}