import React, { Component } from 'react'
import {getAudioType, onClickType, onEditType, SoundCard, SoundCardProps} from '../soundcard/SoundCard';
import './SoundCardList.scss';
import {CategoryCardProps} from "../categorycard/CategoryCard";

type SoundListProps = 
{
    filteredSounds: Array<SoundCardProps>,
    categories: Array<CategoryCardProps>,
    clickCallback: onClickType,
    editCallback: onEditType,
    getAudioCallback: getAudioType,
}

const SoundCardList = (props: SoundListProps) =>
{
    const { clickCallback, editCallback, categories, getAudioCallback } = props;
    const soundCards = props.filteredSounds.map((prop: SoundCardProps, index: number) => {
        const categoryProps = categories.filter(category => prop.categories.includes(category.name))
        return <SoundCard clickCallback={clickCallback} editCallback={editCallback} key={index} id={prop.id}
                          name={prop.name} created_at={prop.created_at} categories={prop.categories}
                          my_play_count_month={prop.my_play_count_month} my_total_play_count={prop.my_total_play_count}
                          total_play_count={prop.total_play_count} play_count_month={prop.play_count_month}
                          categoryProps={categoryProps} getAudioCallback={getAudioCallback}/>;
    });
    return <div className="cardList">{soundCards}</div>;
}

export default SoundCardList;