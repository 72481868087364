import { AUTH_USER_PENDING, AUTH_USER_SUCCESS, AUTH_USER_FAILED } from '../constants';
import { API_SERVER_ADDRESS } from '../../settings';
import axios from 'axios';
export type SignInType = {
    username: string;
    password: string;
}

export type SignInCallbackType = {
    (): void;
}

export const signIn = (formProps: SignInType, callback: SignInCallbackType) => (dispatch: any) =>
{
    const headers = {
        'Content-Type':'application/json'
    }
    axios.post(API_SERVER_ADDRESS+"/token", formProps, {
        headers
    })
    .then((response) => { 
        const tokens = response.data;
        dispatch({type: AUTH_USER_SUCCESS, payload: tokens.access});
        //REMOVE LOCAL STORAGE DATA!
        localStorage.setItem('refresh', tokens.refresh);
        callback();
    })
    .catch(err => { dispatch({type: AUTH_USER_FAILED, payload: err})})
}

export const signOut = () => 
{
    localStorage.removeItem('refresh');
    return {
        type: AUTH_USER_SUCCESS,
        payload: undefined
    };
}

export const accessTokenUpdated = (newToken: string) => {
    console.log("Updating access token to store");
    return {
        type: AUTH_USER_SUCCESS,
        payload: newToken
    }
}