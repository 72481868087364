import { AUTH_USER_PENDING, AUTH_USER_SUCCESS, AUTH_USER_FAILED } from '../constants';
import { SignInType } from '../types';
const INITIAL_STATE: SignInType = {
    authentication: null,
    errorMessage: "",
    isPending: false
}

export default function(state = INITIAL_STATE, action: any = {})
{
    switch(action.type)
    {
        case AUTH_USER_PENDING:
        {
            return Object.assign({}, state, { authentication: null, errorMessage: "", isPending:true })
        }
        case AUTH_USER_SUCCESS:
        {
            //Temporary change until backend authentication changes are done.
            return Object.assign({}, state, { authentication: action.payload, errorMessage:"", isPending:false })   
        }
        case AUTH_USER_FAILED: 
        {
            return Object.assign({}, state, { authentication: null, errorMessage: action.payload, isPending: false });   
        }
        default: 
            return state;
    }
}