import React from 'react';
import './CategoryCard.scss';

export type CategoryCardProps =
{
    id: number | null,
    name: string,
    color_code: string,
    text_color_code: string,
    clickCallback: ((name: string) => void),
    selected: boolean,
}

export const CategoryCard: React.FC<CategoryCardProps> = (props: CategoryCardProps) => {
    const { name, selected, color_code, text_color_code, clickCallback } = props;
    let className = "categoryCard";
    if (selected) className += " selectedCard";
    return (
        <div style={{backgroundColor: color_code}} className={className} onClick={() => { clickCallback(name) }}>
            <h3 className="categoryCardHeader" style={{color: text_color_code}}>{name}</h3>
        </div>
    );
};