import { API_SERVER_ADDRESS } from '../settings';
import axios from 'axios';

export const isAuthenticated = () => {
    //NOTE: THIS IS TEMPORARY. WE DON'T WANT TO STORE ANYTHING 
    //IN LOCAL STORAGE OR SESSION STORAGE.
    //The application should update these values and clear them away
    //when signing out.
    const refresh = localStorage.getItem('refresh');
    // If either one exists we consider the user signed in. 
    // This whole implementation should be removed or heavily reworked later
    // once backend support has been implemented.
    return refresh;
}

export async function getAccessToken () {
    //TODO: Remove, refresh token should be stored at server side into a cookie.
    const refresh = localStorage.getItem('refresh');
    if(!refresh)
        return undefined;

    const body = {
        refresh
    };

    const accessToken = await axios.post(API_SERVER_ADDRESS+'/token/refresh', body)
        .then((response) => {
            if(response.data && response.data.access)
            {
                return response.data.access;
            }
            return undefined;
        })
        .catch((err) => 
        {
            console.log(err);
            return undefined;
        });
    return accessToken;
}