import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import './SignOut.scss';

interface SignOutType{
    ():void;
}

type SignOutProps = {
    signOut: SignOutType;
}

type SignOutState = {
    
}

class SignOut extends React.Component<SignOutProps, SignOutState> {
    componentDidMount(){
        this.props.signOut();
    }
    render() {
        return <h3>You have been signed out</h3>;
    }
}


export default connect(null, actions)(SignOut);