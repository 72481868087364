import React from 'react';
import { connect } from 'react-redux';
import { History } from 'history';

import './SignIn.scss';
import { signIn, SignInType, SignInCallbackType } from '../../../store/actions/index';

type SignInProps = 
{
    onUserSignIn: any;
    username: string;
    password:string;
    history: History;
}

type SignInState = 
{
    username: string;
    password: string;
}

//Note, do not add username and password to redux. Only add jwt to redux.
class SignIn extends React.Component<SignInProps, SignInState> 
{
    constructor(props: SignInProps) 
    {
        super(props);
        this.state = {
            username: "",
            password: ""
        }
    }

    updateUsername = (event: React.ChangeEvent<HTMLInputElement>) => 
    {
        this.setState({ username: event.target.value });
    }

    updatePassword = (event: React.ChangeEvent<HTMLInputElement>) => 
    {
        this.setState({ password: event.target.value });
    }

    signInUser = (e: React.FormEvent) => 
    {
        // onUserSignIn has been defined in the mapDispatchToProps redux-function.
        //We are simply pulling this function from there and using it.
        const { onUserSignIn } = this.props;
        e.preventDefault();
        onUserSignIn({username: this.state.username, password: this.state.password}, () => { this.props.history.push('/dashboard'); });
    }

    render() 
    {
        return (
            <React.Fragment>
                <div className="signInFormDiv">
                    <form className="signInForm" onSubmit={this.signInUser}>
                        <h1>Sign In</h1>
                        <label>Username</label>
                        <input name="username" autoComplete="none" onChange={this.updateUsername}/>
                        <label>Password</label>
                        <input name="password" type="password" autoComplete="none" onChange={this.updatePassword}/>
                        <button>Sign In</button>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => 
{
    return {
        onUserSignIn: (signInForm: SignInType, callback: SignInCallbackType) => dispatch(signIn(signInForm, callback))
    };
}

export default connect(null, mapDispatchToProps)(SignIn);