import React from 'react';
import {connect} from 'react-redux';

import Scroll from '../../../components/scroll/Scroll';
import SoundCardList from '../../../components/soundcardlist/SoundCardList'
import SearchBox from '../../../components/searchbox/SearchBox';
import {SoundCardProps} from '../../../components/soundcard/SoundCard';
import {
    editSound,
    PlaySoundProps,
    playSound,
    requestCategories,
    requestSounds,
    getAudio, GetAudioProps, EditSoundProps
} from '../../../store/actions/sounds';
import './SoundListPage.scss';
import CategoryCardList from "../../../components/categorycardlist/CategoryCardList";
import {CategoryCardProps} from "../../../components/categorycard/CategoryCard";

enum SortType {
    Name = "name",
    CreatedAt = "created_at",
    PlaysTotal = "total_play_count",
    PlaysMonth = "play_count_month",
    MyPlaysTotal = "my_total_play_count",
    MyPlaysMonth = "my_play_count_month",
}

enum SortDirection {
    Ascending = "ascending",
    Descending = "descending",
}

const SortTypeOptions = [
    {value: SortType.Name, display: "Name"},
    {value: SortType.CreatedAt, display: "Creation date"},
    {value: SortType.PlaysTotal, display: "Total play count"},
    {value: SortType.PlaysMonth, display: "Monthly play count"},
    {value: SortType.MyPlaysTotal, display: "My total play count"},
    {value: SortType.MyPlaysMonth, display: "My monthly play count"},
]

const SortDirectionOptions = [
    {value: SortDirection.Ascending, display: "Ascending"},
    {value: SortDirection.Descending, display: "Descending"},
]

type SoundListPageProps =
{
    sounds: Array<SoundCardProps>;
    categories: Array<CategoryCardProps>;
    onRequestCategories: React.FunctionComponent<string>;
    onRequestSounds: React.FunctionComponent<string>;
    onPlaySoundRequest: React.FunctionComponent<PlaySoundProps>;
    onEditSoundRequest: React.FunctionComponent<EditSoundProps>;
    onGetAudio: React.FunctionComponent<GetAudioProps>;
    isPending: boolean;
    authentication: string;
}

class SoundListPage extends React.Component<SoundListPageProps>
{
    state = {
        sortBy: SortType.Name,
        sortDirection: SortDirection.Ascending,
        override: false,
        searchField: "",
    }

    getFilteredSounds = () => 
    {
        const { categories, sounds } = this.props;
        const searchField = this.state.searchField
        this.sortListByName(categories);
        const selectedCategories = categories.filter(c => c.selected);
        if (sounds.length)
        {
            const filtered = sounds.filter(sound =>
            {
                const searchOk = sound.name.toLowerCase().replaceAll(" ", "_").includes(
                    searchField.toLowerCase().replaceAll(" ", "_"))
                const categoryFilterOk = (!selectedCategories.length ||
                    selectedCategories.some(c => sound.categories.includes(c.name)));
                return searchOk && categoryFilterOk;
            });
            this.sortSoundElements(filtered, this.state.sortBy, this.state.sortDirection);
            return filtered;
        }
        else 
        {
            return [];
        }
    }

    sortListByName = (arr: Array<SoundCardProps | CategoryCardProps>) => 
    {
        arr.sort((a, b) => a.name.localeCompare(b.name));
    }

    sortSoundElements = (arr: Array<SoundCardProps>, type: SortType, direction: SortDirection) =>
    {
        if(type === SortType.Name || type == SortType.CreatedAt) {
            arr.sort((a,b) => a[type].localeCompare((b[type])));
        }
        else {
            arr.sort((a, b) => a[type] > b[type] ? 1 : -1);
        }
        if (direction == SortDirection.Descending) {
            arr.reverse();
        }
    }

    getCategories = () =>
    {
        const {categories } = this.props;
        return categories;
    }

    componentDidMount()
    {
        this.fetchCategories();
        this.fetchSounds();
    }

    fetchCategories = () =>
    {
        const { authentication } = this.props;
        this.props.onRequestCategories(authentication);
    }

    fetchSounds = () => 
    {
        const { authentication } = this.props;
        this.props.onRequestSounds(authentication);
    }

    onSoundClicked = (id: number) =>
    {
        const { authentication } = this.props;
        const override = this.state.override;
        this.props.onPlaySoundRequest({id, override, authentication});
    }

    onSoundEditClicked = (id: number) =>
    {
        const { authentication } = this.props;
        this.props.onEditSoundRequest({id, authentication});
    }

    onCategoryClick = (name: string, randomize: boolean) =>
    {
        const category = this.props.categories.find(category => category.name === name)
        if (category !== undefined)
        {
            randomize ? 
            this.playRandomSoundFromCategory(category.name) : 
            category.selected = !category.selected;
        }
        this.forceUpdate()
    }

    playRandomSoundFromCategory(category: string)
    {
        const { sounds } = this.props;
        const categorySounds = sounds.filter((sound) => sound.categories.includes(category));
        const randomSound = categorySounds[Math.floor(Math.random()*categorySounds.length)];
        if(randomSound)
        {
            this.onSoundClicked(randomSound.id);
        }
    }

    onClear = () =>
    {
        this.props.categories.forEach(function (category){category.selected = false});
        this.setState({searchField: ""});
        this.forceUpdate();
    }

    onSelectOrder = (event: React.ChangeEvent<HTMLSelectElement>) =>
    {
        this.setState({sortBy: event.target.value});
    }

    onSelectOrderDirection = (event: React.ChangeEvent<HTMLSelectElement>) =>
    {
        this.setState({sortDirection: event.target.value});
    }

    onOverrideChange = () =>
    {
        this.setState({override: !this.state.override});
    }

    onPlayAudio = (id: number) =>
    {
        const { authentication } = this.props;
        this.props.onGetAudio({id, authentication});
    }

    onSearchChange = (event: any) =>
    {
        this.setState({searchField: event.target.value})
    }

    render()
    {
        const { isPending } = this.props;
        if (isPending)
        {
            return (
                <div className="dashboardSearch">
                    <h1>Loading</h1>
                </div>
            );
        }
        else 
        {
            const filtered: Array<SoundCardProps> = this.getFilteredSounds();
            const categories: Array<CategoryCardProps> = this.getCategories();
            return(
                <div>
                    <div className="sortSearchContainer">
                        <div className="dashboardSort">
                            <select className="orderSelect" onChange={this.onSelectOrder}>
                                {SortTypeOptions.map(sortOption => (
                                    <option key={sortOption.value} value={sortOption.value}>
                                        {sortOption.display}
                                    </option>
                                ))}
                            </select>
                            <select className="orderSelect" onChange={this.onSelectOrderDirection}>
                                {SortDirectionOptions.map(sortDirection => (
                                    <option key={sortDirection.value} value={sortDirection.value}>
                                        {sortDirection.display}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="dashboardSearch">
                            <SearchBox searchChange={this.onSearchChange} searchField={this.state.searchField}/>
                        </div>
                        <div className="dashboardOptions">
                            <label>
                                <input type="checkbox" name="override" checked={this.state.override}
                                       onChange={this.onOverrideChange}/>
                                &nbsp;Override
                            </label><br/>
                        </div>
                    </div>
                    <div className="dashboardCategories">
                        <CategoryCardList categories={categories} clearCallback={this.onClear}
                                          clickCallback={this.onCategoryClick}/>
                    </div>
                    <Scroll>
                        <SoundCardList filteredSounds={filtered} categories={categories}
                                       clickCallback={this.onSoundClicked} editCallback={this.onSoundEditClicked}
                                       getAudioCallback={this.onPlayAudio} />
                    </Scroll>                    
                </div>
            );
        }
    }
}

const mapStateToProps = (state: any) => 
{
    return {
        //This value comes from the reducer.
        isPending: state.requestSounds.isPending,
        sounds: state.requestSounds.sounds,
        categories: state.requestCategories.categories,
        authentication: state.signIn.authentication
    }
}

const mapDispatchToProps = (dispatch: any) =>
{
    return {
        onRequestCategories: (authentication: string) => dispatch(requestCategories(authentication)),
        onRequestSounds: (authentication: string) => dispatch(requestSounds(authentication)),
        onPlaySoundRequest: (props: PlaySoundProps) => dispatch(playSound(props)),
        onGetAudio: (props: GetAudioProps) => dispatch(getAudio(props)),
        onEditSoundRequest: (props: EditSoundProps) => dispatch(editSound(props)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SoundListPage);
