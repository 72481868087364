export const CHANGE_SEARCH_FIELD = "CHANGE_SEARCH_FIELD";
export const REQUEST_CATEGORIES_PENDING = "REQUEST_CATEGORIES_PENDING";
export const REQUEST_CATEGORIES_SUCCESS= "REQUEST_CATEGORIES_SUCCESS";
export const REQUEST_CATEGORIES_FAILED = "REQUEST_CATEGORIES_FAILED";
export const REQUEST_SOUNDS_PENDING = "REQUEST_SOUNDS_PENDING";
export const REQUEST_SOUNDS_SUCCESS= "REQUEST_SOUNDS_SUCCESS";
export const REQUEST_SOUNDS_FAILED = "REQUEST_SOUNDS_FAILED";
export const AUTH_USER_PENDING = "AUTH_USER_PENDING"; 
export const AUTH_USER_SUCCESS = "AUTH_USER_SUCCESS";
export const AUTH_USER_FAILED = "AUTH_USER_FAILED";
export const PLAY_SOUND_SUCCESS = "PLAY_SOUND_SUCCESS";
export const PLAY_SOUND_FAILED = "PLAY_SOUND_FAILED";
export const EDIT_SOUND_START = "EDIT_SOUND_START";
export const EDIT_SOUND_FAILED = "EDIT_SOUND_FAILED";
export const EDIT_SOUND_SUCCESS = "EDIT_SOUND_SUCCESS";
export const PLAY_IN_BROWSER_SUCCESS = "PLAY_IN_BROWSER_SUCCESS";
export const PLAY_IN_BROWSER_FAILED = "PLAY_IN_BROWSER_FAILED";
export const PREVIEW_CLIP_SUCCESS = "PREVIEW_CLIP_SUCCESS";
export const PREVIEW_CLIP_FAILED = "PREVIEW_CLIP_FAILED";
export const CREATE_CLIP_SUCCESS = "CREATE_CLIP_SUCCESS";
export const CREATE_CLIP_FAILED = "CREATE_CLIP_FAILED";
