import React from 'react';
import './CategoryLabel.scss';
import {CategoryCardProps} from "../categorycard/CategoryCard";


export type CategoryLabelProps =
{
    soundId: number,
    categoryProps: Array<CategoryCardProps>,
}

function sortCategoryPropsBasedOnSelection(categoryProps: Array<CategoryCardProps>)
{
    const sortedCategoryProps = categoryProps.sort((a, b) => {
       if (a.selected && b.selected) return 0;
       else if (a.selected && !b.selected) return -1;
       else return 1;
    });
    return sortedCategoryProps
}

export function CategoryLabel(props: CategoryLabelProps)
{
    const { categoryProps, soundId } = props;
    const sortedCategoryProps = sortCategoryPropsBasedOnSelection(categoryProps);
    const hasSelectedCategories = sortedCategoryProps.filter(c => c.selected).length > 0;
    return (
            <div className="categoryLabelContainer">
                {sortedCategoryProps.map(category => {
                    let className = "categoryLabel";
                    const key = soundId + "_" + category.name;
                    if (category.selected) className += " selectedCategory";
                    return <div key={key} style={{backgroundColor: category.color_code}} className={className}>
                                <p key={key+"p"} style={{color: category.text_color_code}}>
                                    {category.name}
                                </p>
                                {!category.selected && hasSelectedCategories &&
                                    <div className="notSelectedOverlay"></div>
                                }
                           </div>
                })}
            </div>
    );
}