import React, {useEffect, useState} from 'react';
import './CategoryCardList.scss';
import {CategoryCard, CategoryCardProps} from "../categorycard/CategoryCard";
type CategoryListProps =
{
    categories: Array<CategoryCardProps>,
    clickCallback: (name: string, randomize: boolean) => void,
    clearCallback: () => void,
}

const CategoryCardList = (props: CategoryListProps) =>
{

    useEffect(() => {
        const audio = document.getElementById("soundPlayer") as HTMLAudioElement
        audio.volume = 0.25
    }, [])

    const [randomize, toggleIsOn] = useState(false);

    const toggleRandomized = () => {
        toggleIsOn(!randomize);
    }

    const cardClicked = (name: string) => {
        clickCallback(name, randomize);
    }

    const { clickCallback, clearCallback } = props;
    const categoryCards = props.categories.map((prop: CategoryCardProps, index: number) => {
        prop.clickCallback = cardClicked
        return <CategoryCard key={index} {...prop} />;
    });
    return (
        <>
            <div className="categoryClearContainer">
                <CategoryCard name="Clear" color_code="#FFF" text_color_code="#000" clickCallback={clearCallback}
                              selected={false} id={null}/>
                <CategoryCard name="Randomize" color_code="#FFF" text_color_code="#000" clickCallback={toggleRandomized}
                              selected={randomize} id={null}/>
                <div className="soundPlayerContainer">
                    <audio id="soundPlayer" controls />
                </div>
            </div>
            <div className="categoryCardList">{categoryCards}</div>
        </>
    )
}

export default CategoryCardList;