import React from "react";
import { connect } from "react-redux";
import axios from 'axios';
import { API_SERVER_ADDRESS } from '../settings';
import { getAccessToken } from '../store/helpers';

import Navigation from './navigation/Navigation';
import ErrorBoundry from './errorboundry/ErrorBoundry';
import { ApplicationState } from '../store/types';
import { accessTokenUpdated, signOut } from '../store/actions';
import './App.scss';

type RefreshCallbackFunction = (accessToken: string) => void;
type RefreshRefusedCallbackFunction = () => void;

class App extends React.Component<any, ApplicationState>
{
    componentDidMount() {
        this.setAxiosInterceptor();
        const { authentication } = this.props;
        if (!authentication)
            this.tryToUpdateAccessTokenIfRefreshTokenExists();
    }

    tryToUpdateAccessTokenIfRefreshTokenExists = () => {
        const { onAccessTokenRefreshed, onRefreshTokenRefused } = this.props;
        this.refreshAccessToken(onAccessTokenRefreshed, onRefreshTokenRefused);
    }

    refreshAccessToken = (callback: RefreshCallbackFunction, refusedCallback: RefreshRefusedCallbackFunction) => {
        const accessTokenPromise = getAccessToken();
        accessTokenPromise.then(token => 
        {
            callback(token);
        })
        .catch(error => {
            refusedCallback();
            Promise.reject(error);
        })
    }

    setAxiosInterceptor = () => {
        const { onAccessTokenRefreshed, onRefreshTokenRefused } = this.props;
        axios.interceptors.response.use((response) => {
            // Return a successful response back to the calling service
            return response;
        }, (error) => {
            // Return any error which is not due to authentication back to the calling service
            if (error.response.status !== 401) {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }
            // Logout user if token refresh didn't work.
            if (error.config.url === API_SERVER_ADDRESS+'/token' ||
                error.config.url === API_SERVER_ADDRESS+'/token/refresh') {
                //Sign out user.
                console.log("Not authenticated.");
                onRefreshTokenRefused();
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }
            const accessTokenPromise = getAccessToken();
            accessTokenPromise.then(token => {
                onAccessTokenRefreshed(token);
                const config = error.config;
                config.headers['Authorization'] = `Bearer ${token}`;

                return new Promise((resolve, reject) => {
                    axios.request(config).then(response => {
                        resolve(response);
                    }).catch((error) => {
                        reject(error);
                    })
                });
            })
            .catch(err => Promise.reject(err));
        });
    }

    render() {
        return (
            <div className="screen">
                <Navigation />
                <div className="container">
                    <ErrorBoundry>
                        {this.props.children}
                    </ErrorBoundry>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        //This value comes from the reducer.
        isPending: state.requestSounds.isPending,
        error: state.requestSounds.error,
        authentication: state.signIn.authentication
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        onAccessTokenRefreshed: (newToken: string) => dispatch(accessTokenUpdated(newToken)),
        onRefreshTokenRefused: () => dispatch(signOut())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);