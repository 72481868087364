import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import logo from './devduck.png'

import './Navigation.scss'
type NavigationState = 
{
    authentication: string | undefined;
}

class Navigation extends React.Component<any, NavigationState>
{
    constructor(props: unknown)
    {
        super(props);
    }
    
    render()
    {
        const { authentication } = this.props;
        if (authentication)
        {
            return this.loggedInView();
        }
        return this.loggedOutView();
    }

    loggedInView = () => 
    {
        return (
            <div className="mainNav">
                <div className="logoContainer">
                    <img src={logo} alt="Logo" className="logo"/>
                    <Link to="/">DBot</Link>
                </div>
                <Link to="/dashboard">Dashboard</Link>
                <Link to="/addclip">Add a clip</Link>
                <a href="https://www.dbot.devduck.fi/admin/">Admin</a>
                <Link to="/signout">Sign Out</Link>
            </div>
        );
    }

    loggedOutView = () => 
    {
        return (
            <div className="mainNav">
                <div className="logoContainer">
                    <img src={logo} alt="Logo" className="logo"/>
                    <Link to="/">DBot</Link>
                </div>
                <Link to="/signin">Sign In</Link>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => 
{
    return {
        authentication: state.signIn.authentication
    };
}

export default connect(mapStateToProps, null)(Navigation);