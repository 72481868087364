import React, {ReactElement} from 'react';
import './SoundCard.scss';
import {CategoryCardProps} from "../categorycard/CategoryCard";
import {CategoryLabel} from "../categorylabel/CategoryLabel";

export interface onClickType {
    (id: number): void;
}

export interface onEditType {
    (id: number): void;
}

export interface getAudioType {
    (id: number): void;
}

export type SoundCardProps = 
{
    id: number,
    name: string,
    created_at: string,
    my_total_play_count: number,
    my_play_count_month: number,
    play_count_month: number,
    total_play_count: number,
    categories: Array<string>,
    categoryProps: Array<CategoryCardProps>,
    clickCallback: onClickType,
    editCallback: onEditType,
    getAudioCallback: getAudioType,
}

export function SoundCard(props: SoundCardProps): ReactElement
{
    const { name, id, categoryProps, clickCallback, editCallback } = props;
    return (
        <div onClick={() => { clickCallback(id) }} className="soundCard"
             onContextMenu={(e) => { e.preventDefault(); editCallback(id); } } >
            <CategoryLabel categoryProps={categoryProps} soundId={props.id}/>
            <div className="soundCardHeaderContainer">
                <h3 className="soundCardHeader">{name.replaceAll("_", " ")}</h3>
            </div>
            <SoundCardInfo {...props} />
        </div>
    );
}

const SoundCardInfo = (props: SoundCardProps) => {
    const [showTooltip, setShowTooltip] = React.useState(false);
    const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setShowTooltip(!showTooltip);
    }
    const onGetAudio = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        props.getAudioCallback(props.id)
    }
    const formatted_date = new Date(props.created_at).toISOString().split('T')[0]
    return (
        <div className="tooltipContainer">
            <b onClick={onClick} className="showTooltipButton">&#9432;</b>
            {showTooltip ?
                <div className="tooltip">
                    <span>Created at: {formatted_date}</span><br/>
                    <span>Total play count: {props.total_play_count}</span><br/>
                    <span>Monthly play count: {props.play_count_month}</span><br/>
                    <span>My total play count: {props.my_total_play_count}</span><br/>
                    <span>My monthly play count: {props.my_play_count_month}</span><br/>
                </div>
                : null
            }
            <b onClick={onGetAudio} className="playInBrowserButton">&#8227;</b>
        </div>
    )

}
