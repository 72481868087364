import React from 'react';
import './SearchBox.scss';
type SearchBoxProps = 
{
    //TODO: Figure out the correct type.
    searchChange: any;
    searchField: string;
}

const SearchBox = (props: SearchBoxProps) =>
{
    return (
        <input type="search" placeholder="Search" className="searchBox" onChange={props.searchChange}
               value={props.searchField}/>
    );
}

export default SearchBox;