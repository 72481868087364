import React from 'react';

type ErrorBoundryState = 
{
    hasError:boolean;
}
class ErrorBoundry extends React.Component<unknown, ErrorBoundryState> 
{
    constructor(props: unknown)
    {
        super(props);
        this.state = {
            hasError: false
        }
    }

    componentDidCatch(error: Error, info: React.ErrorInfo)
    {
        this.setState({hasError: true});
    }

    render() 
    {
        if(this.state.hasError)
        {
            return <h1>Something went wrong.</h1>
        }
        else 
        {
            return this.props.children;
        }
    }
}

export default ErrorBoundry;