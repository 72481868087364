import { combineReducers } from 'redux';
import signIn from './auth';
import {requestSounds, previewClip} from './sounds';
import {requestCategories} from "./categories";

export default combineReducers({
    signIn, //Same as authenticate: authenticate
    requestSounds,
    requestCategories,
    previewClip,
});