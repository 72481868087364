import React from 'react';
import './AddClipPage.scss';
import {
    createClip, CreateClipProps,
    previewClip, PreviewClipProps,
    requestCategories,
} from "../../../store/actions/sounds";
import {connect} from "react-redux";
import {CategoryCardProps} from "../../../components/categorycard/CategoryCard";
import Select from "react-select";
import chroma from "chroma-js";
import YouTube from "react-youtube";

type AddClipPageProps =
{
    onRequestCategories: React.FunctionComponent<string>;
    onPreviewClip: React.FunctionComponent<PreviewClipProps>;
    onCreateClip: React.FunctionComponent<CreateClipProps>;
    categories: Array<CategoryCardProps>;
    clipStatus: string;
    clipErrors: string;
    authentication: string;
}

interface FormState
{
    name: string,
    ytUrl: string,
    categories: Array<string>,
    startMs: number,
    endMs: number,
}

interface AddClipPageState
{
    ytId: string,
    timestampMs: number,
    duration: number,
    formState: FormState,
    showYt: boolean,
}

export interface ClipResponse
{
    non_field_errors: Array<string>,
    name: Array<string>,
    yt_url: Array<string>,
    categories: Array<string>,
    start_ms: Array<string>,
    end_ms: Array<string>,
}

class AddClipPage extends React.Component<AddClipPageProps, AddClipPageState>
{

    state = {
        formState: {
            name: "",
            ytUrl: "",
            categories: [],
            startMs: 0,
            endMs: 0,
        },
        showYt: false,
        ytId: "",
        timestampMs: 0,
        duration: 0
    }

    fetchCategories = () =>
    {
        const { authentication } = this.props;
        this.props.onRequestCategories(authentication);
    }

    getCategories = () =>
    {
        const {categories } = this.props;
        return categories;
    }

    onYtUrlChange = (e: any) =>
    {
        try
        {
            const url = new URL(e.target.value);
            let ytId = null;
            if (url.origin === "https://www.youtube.com")
            {
                ytId = url.searchParams.get("v");
            }
            else if (url.origin === "https://youtu.be")
            {
                ytId = url.pathname.substring(1);
            }
            if (ytId && this.state.ytId !== ytId)
            {
                this.setState({ ytId: ytId, showYt: false});
                setTimeout(() => {
                    this.setState({showYt: true});
                }, 10); // THIS IS A HACK
            }
            else if (ytId === null)
            {
                this.setState({ytId: "", showYt: false});
            }
        }
        catch(e: unknown)
        {
            this.setState({ytId: ""});
            console.error("Invalid URL");
        }
    }

    handleInputChange = (event: any) => {
        const target = event.target;
        const formState = this.state.formState;
        (<K extends keyof FormState>(k: K) => {formState[k] = target.value})(target.name);

        this.setState({formState: formState});
    }

    handleCategoryChange = (selectedCategories: any) => {
        const formState = this.state.formState;

        formState.categories = selectedCategories.map((item: any) => item.value);
        this.setState({formState: formState});
    }

    setEndTimeMs = () => {
        const formState = this.state.formState
        formState.endMs = this.state.timestampMs
        this.setState({formState: formState});
    }

    setEndTimeMax = () => {
        const formState = this.state.formState;
        formState.endMs = this.state.duration;
        this.setState({formState: formState});
    }

    setStartTimeMs = () => {
        const formState = this.state.formState;
        formState.startMs = this.state.timestampMs;
        this.setState({formState: formState});
    }

    onYtPause(event: any) {
        const currentTime = event.target.playerInfo.currentTime;
        this.setState({timestampMs: Math.round(currentTime * 1000)});
    }

    onYtReady(event: any) {
        const duration = Math.round(event.target.playerInfo.duration * 1000);
        this.setState({formState: {...this.state.formState, endMs: duration}, duration: duration});
    }

    componentDidMount() {
        this.fetchCategories();
        const audio = document.getElementById("previewPlayer") as HTMLAudioElement;
        audio.volume = 0.35;
    }

    onSubmit = (e: any) =>
    {
        e.preventDefault()
        const { authentication } = this.props;
        const categories = [];
        if (typeof e.target.categories[Symbol.iterator] === 'function')
            for (const category of e.target.categories.values()) {
                categories.push(category.value);
            }
        else if (e.target.categories.value != "")
            categories.push(e.target.categories.value);
        const submitProps = { ...this.state.formState, authentication};

        if (e.nativeEvent.submitter.value === "preview")
            this.props.onPreviewClip(submitProps);
        else
            this.props.onCreateClip(submitProps);

    }

    render()
    {
        let timestamp = this.state.timestampMs;
        if (this.props.clipStatus === "SUCCESS") {
            const form = document.getElementById("createClipForm") as HTMLFormElement;
            form.reset();
            timestamp = 0;
        }
        const showEmbeddedYoutube = this.state.ytId !== "" && this.state.showYt;
        const clipResponse = this.props.clipErrors ? JSON.parse(this.props.clipErrors) : null;
        return (
            <div className="addAClip">
                <div className="addClipForm">
                    <AddClipForm submitHandler={this.onSubmit} categories={this.getCategories()}
                                 onYtUrlChange={this.onYtUrlChange} timestampMs={timestamp}
                                 errors={clipResponse} onInputChange={this.handleInputChange}
                                 setEndTimeMs={this.setEndTimeMs} setStartTimeMs={this.setStartTimeMs}
                                 formState={this.state.formState} onCategoryChange={this.handleCategoryChange}
                                 duration={this.state.duration} setEndTimeMax={this.setEndTimeMax}
                    />
                    <audio id="previewPlayer" controls />
                    { this.props.clipStatus === "SUCCESS" ?
                        <p style={{"color": "green", "fontWeight": "bold"}}>SUCCESS!</p>
                        : null
                    }
                </div>
                <div className="youtubeContainer">
                { showEmbeddedYoutube ?
                    <YouTube videoId={this.state.ytId} onPause={ (e) => this.onYtPause(e)}
                     onReady={(e) => this.onYtReady(e)}/>
                    : null
                }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) =>
{
    return {
        categories: state.requestCategories.categories,
        authentication: state.signIn.authentication,
        clipStatus: state.previewClip.status,
        clipErrors: state.previewClip.errors,
    }
}

const mapDispatchToProps = (dispatch: any) =>
{
    return {
        onRequestCategories: (authentication: string) => dispatch(requestCategories(authentication)),
        onPreviewClip: (props: PreviewClipProps) => dispatch(previewClip(props)),
        onCreateClip: (props: CreateClipProps) => dispatch(createClip(props))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddClipPage);


type AddClipFormProps =
{
    timestampMs: number,
    duration: number,
    submitHandler: any,
    onYtUrlChange: any,
    onInputChange: any,
    onCategoryChange: any,
    setStartTimeMs: any,
    setEndTimeMs: any,
    setEndTimeMax: any,
    formState: FormState,
    categories: Array<CategoryCardProps>,
    errors: ClipResponse,
}

class AddClipForm extends React.Component<AddClipFormProps>
{
    render()
    {
        const options = this.props.categories.map(category =>(
            {value:category.id, label:category.name, color:category.text_color_code, bgcolor: category.color_code}
        ));
        const colorStyles = {
            option: (styles: any, data: any) => {
                const color = chroma(data.data.bgcolor);
                return {
                    ...styles,
                    backgroundColor: data.isFocused ? color.alpha(0.3).css() : color.css(),
                    color: data.data.color,
                };
            },
            input: (styles: any, data: any) => {
                return {
                    ...styles,
                };
            },
            multiValue: (styles: any, data: any) => {
                const color = chroma(data.data.bgcolor);
                return {
                    ...styles,
                    color: data.data.color,
                    backgroundColor: color.alpha(0.8).css(),
                };
            },
        };
        return (
            <>
            { this.props.errors && this.props.errors.non_field_errors ?
                <strong className="formError">{this.props.errors.non_field_errors}</strong>: null
            }
            <form onSubmit={this.props.submitHandler} id="createClipForm">
                <label>
                    Name
                    <input type="text" name="name" value={this.props.formState.name} required onChange={this.props.onInputChange}/>
                </label>
                { this.props.errors && this.props.errors.name ?
                    <strong className="formError">{this.props.errors.name}</strong>: null
                }
                <label>
                    YouTube URL
                    <input type="text" name="ytUrl" value={this.props.formState.ytUrl} onBlur={this.props.onYtUrlChange} required
                    onChange={this.props.onInputChange}/>
                </label>
                { this.props.errors && this.props.errors.yt_url ?
                    <strong className="formError">{this.props.errors.yt_url}</strong>: null
                }
                <label className="categoriesSelect">
                    Categories
                    <Select name="categories"
                            options={options}
                            styles={colorStyles}
                            isMulti
                            className="categoryMultiSelect"
                            onChange={this.props.onCategoryChange}/>
                </label>
                { this.props.errors && this.props.errors.categories ?
                    <strong className="formError">{this.props.errors.categories}</strong>: null
                }
                <label>
                    Start time (ms)
                    <input type="number" name="startMs" value={this.props.formState.startMs} required
                           onChange={this.props.onInputChange} min="0"/>
                    <button onClick={this.props.setStartTimeMs} type="button">Set to last pause ({this.props.timestampMs})</button>
                </label>
                { this.props.errors && this.props.errors.start_ms ?
                    <strong className="formError">{this.props.errors.start_ms}</strong>: null
                }
                <label>
                    End time (ms)
                    <input type="number" name="endMs" value={this.props.formState.endMs} required
                           onChange={this.props.onInputChange} min="0"/>
                    <button onClick={this.props.setEndTimeMs} type="button">Set to last pause ({this.props.timestampMs})</button>
                    <button onClick={this.props.setEndTimeMax} type="button">Set to max ({this.props.duration})</button>
                </label>
                { this.props.errors && this.props.errors.end_ms ?
                    <strong className="formError">{this.props.errors.end_ms}</strong>: null
                }
                <input type="submit" value="submit" className="submitClip"/>
                <input type="submit" value="preview" className="previewClip"/>
            </form>
            </>
        )
    }

}
